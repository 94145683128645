import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import CoursePreview from "../components/CoursePreview";

const Courses = ({
  data: {
    allMdx: { nodes: courses },
  },
  pageContext: { unite, lessonUnit },
  location,
}) => {
  return (
    <Layout title={`دروس الوحدة: ${unite}`} location={location}>
      <div className="posts">
        {courses.length > 0 ? (
          courses.map((course, index) => {
            return (
              <CoursePreview
                key={index}
                course={{
                  title: course.frontmatter.title,
                  slug: course.fields.slug,
                  fluid: {
                    ...course.frontmatter.thumbnail.childImageSharp.fluid,
                    originalName: course.frontmatter.thumbnail.childImageSharp.fluid?.originalName?.replace(
                      /Banner|.png/g,
                      ""
                    ),
                  },
                  excerpt: course.frontmatter.description,
                }}
                loading={index > 3 ? "eager" : "lazy"}
              />
            );
          })
        ) : (
          <p>سوف يتم إضافة الدروس قريبا</p>
        )}
      </div>
    </Layout>
  );
};

export default Courses;

export const query = graphql`
  query($unite: String!) {
    allMdx(
      filter: {
        frontmatter: { unite: { eq: $unite }, lessonType: { eq: "درس" } }
      }
    ) {
      nodes {
        fields {
          slug
        }
        frontmatter {
          title
          description
          thumbnail {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
                originalName
              }
            }
          }
        }
        excerpt
      }
    }
  }
`;
