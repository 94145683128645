import React from "react";
import { Link } from "gatsby";

import { Card } from "./atomes";

const CoursePreview = ({ course, loading }) => {
  const CardLink = ({ children }) => {
    return <Link to={course.slug}>{children}</Link>;
  };
  return <Card item={course} loading={loading} Wrapper={CardLink} />;
};

export default CoursePreview;
